import * as React from "react";
import { SVGProps } from "react";
export default function SvgIconJuke(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 64 64"
      className="icon-juke_svg__h-root-0-2-12 icon-juke_svg__h-colorPrimary-0-2-15 icon-juke_svg__o-iconInner-0-2-258"
      {...props}
    >
      <path
        d="M61.987 12.45l-14.65 20.864c-.457.65-1.167.627-1.583-.051L33.13 12.837c-.327-.556-.701-.835-1.13-.835-.429 0-.803.279-1.133.835l-12.62 20.426c-.417.678-1.127.7-1.585.051L2.012 12.45a1.134 1.134 0 00-1.28-.387c-.46.16-.732.602-.732 1.1v36.68c0 .882.717 1.6 1.6 1.6h60.8c.88 0 1.6-.718 1.6-1.6v-36.68c0-.498-.275-.94-.74-1.1-.46-.16-.972-.01-1.273.387"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
