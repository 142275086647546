import { create } from "zustand";
import { radioStream } from "lib/constants";

export type TrackType = {
  albumArt: string;
  artist: string;
  title: string;
  full: string;
};

export type SongHistory = {
  played_at: number;
  is_request: boolean;
  song: {
    text: string;
    artist: string;
    title: string;
    album: string;
    art: string;
    custom_fields: {
      is_hot_release: null;
      year: null;
    };
  };
};

export type PlayerStoreState = {
  firstPlay: boolean;
  isMuted: boolean;
  isPlaying: boolean;
  isPaused: boolean;
  file: string;
  volume: number;
  // volumeIcon: 'mdi-volume-high',
  isReady: boolean;
  nowPlaying: TrackType;
  nextTrack: TrackType;
  previousTrack: TrackType;

  songHistory: SongHistory[];

  isRadio: boolean;

  play: () => void;
  pause: () => void;
  setFirstPlay: (firstPlay: boolean) => void;
  setIsMuted: (isMuted: boolean) => void;
  setFile: (file: string) => void;
  setVolume: (volume: number) => void;
  setIsReady: (isReady: boolean) => void;
  setIsPlaying: (isPlaying: boolean) => void;
  setNowPlaying: (nowPlaying: TrackType) => void;
  setNextTrack: (nextTrack: TrackType) => void;
  setPreviousTrack: (previousTrack: TrackType) => void;
  setSongHistory: (songHistory: SongHistory[]) => void;
  resetFile: () => void;
};

export const usePlayerStore = create<PlayerStoreState>(set => ({
  firstPlay: true,
  isMuted: false,
  isPlaying: false,
  isPaused: true,
  file: "https://audio.ztack.nl/radio/8000/ztack",
  volume: 75,
  // volumeIcon: 'mdi-volume-high',
  isReady: false,
  nowPlaying: {
    albumArt: "/album_art_placeholder.svg",
    artist: "ZTACK",
    title: "Shuffle Your Day",
    full: "ZTACK - Shuffle Your Day",
  },
  nextTrack: {
    albumArt: "/album_art_placeholder.svg",
    artist: "ZTACK",
    title: "Shuffle Your Day",
    full: "ZTACK - Shuffle Your Day",
  },
  previousTrack: {
    albumArt: "/album_art_placeholder.svg",
    artist: "ZTACK",
    title: "Shuffle Your Day",
    full: "ZTACK - Shuffle Your Day",
  },
  songHistory: [],
  isRadio: true,
  setFirstPlay: (firstPlay: boolean) => set({ firstPlay }),
  setIsMuted: (isMuted: boolean) => set({ isMuted }),
  play: () =>
    // @ts-ignore
    set(state => {
      if (state.isPlaying) {
        return {};
      }
      return { isPaused: false };
    }),
  pause: () => set({ isPaused: true, isPlaying: false }),
  setFile: (file: string) => set({ file, isRadio: false }),
  setVolume: (volume: number) => set({ volume }),
  setIsReady: (isReady: boolean) => set({ isReady }),
  setIsPlaying: (isPlaying: boolean) => set({ isPlaying }),
  setNowPlaying: (nowPlaying: TrackType) => set({ nowPlaying }),
  setNextTrack: (nextTrack: TrackType) => set({ nextTrack }),
  setPreviousTrack: (previousTrack: TrackType) => set({ previousTrack }),
  setSongHistory: (songHistory: SongHistory[]) => set({ songHistory }),
  resetFile: () => set({ file: radioStream, isRadio: true }),
}));
