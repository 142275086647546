import classNames from "classnames";
// @ts-ignore
import NchanSubscriber from "nchan";
import Image from "next/image";
import { PropsWithChildren, useEffect, useLayoutEffect, useState } from "react";
import { radioStream } from "lib/constants";
import { usePlayerStore } from "hooks/usePlayerStore";
import { SvgPause, SvgPlay } from "assets/images";

export default function PlayerProvider({ children }: PropsWithChildren<{}>) {
  const store = usePlayerStore();
  const [audio, setAudio] = useState<HTMLAudioElement | null>(null);
  const [playerVisible, setPlayerVisible] = useState<boolean>(true);

  let playerTimeout: NodeJS.Timeout | null = null;

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    function handleScroll() {
      if (playerTimeout != null) {
        clearTimeout(playerTimeout);
      }

      setPlayerVisible(false);

      playerTimeout = setTimeout(() => {
        setPlayerVisible(true);
      }, 1000);
    }
  }, []);

  useEffect(() => {
    const socket = new WebSocket("wss://audio.ztack.nl/api/live/nowplaying/websocket");

    socket.onopen = function (e) {
      socket.send(
        JSON.stringify({
          subs: {
            "station:ztack": {},
          },
        }),
      );
    };

    socket.onmessage = function (e) {
      let response = JSON.parse(e.data);
      const data = response?.pub?.data?.np || null;
      let nowPlaying = data?.now_playing?.song || null;
      let nextTrack: any;
      let previousTrack: any;

      console.log("np", data);

      if (!data) {
        return;
      }

      if (data.song_history !== null) {
        store.setSongHistory(data.song_history);
      }

      if (data.playing_next !== null) {
        nextTrack = data.playing_next.song;
      }

      if (data.song_history.length > 0) {
        previousTrack = data.song_history[0].song;
      }

      if (store.nowPlaying.artist !== nowPlaying.artist && store.nowPlaying.title !== nowPlaying.title) {
        store.setNowPlaying({
          ...store.nowPlaying,
          albumArt: nowPlaying.art,
        });

        if (nextTrack) {
          store.setNextTrack({
            artist: nextTrack.artist,
            title: nextTrack.title,
            full: `${nowPlaying.artist} - ${nowPlaying.title}`,
            albumArt: nextTrack.art,
          });
        }

        if (previousTrack) {
          store.setPreviousTrack({
            artist: previousTrack.artist,
            title: previousTrack.title,
            full: `${previousTrack.artist} - ${previousTrack.title}`,
            albumArt: previousTrack.art,
          });
        }

        if ("mediaSession" in navigator) {
          navigator.mediaSession.metadata = new MediaMetadata({
            title: nowPlaying.title,
            artist: nowPlaying.artist,
            album: "ZTACK",
            artwork: [
              { src: nowPlaying.art, sizes: "256x256", type: "image/png" },
              { src: nowPlaying.art, sizes: "512x512", type: "image/png" },
            ],
          });
        }
      }

      store.setNowPlaying({
        artist: nowPlaying.artist,
        title: nowPlaying.title,
        full: `${nowPlaying.artist} - ${nowPlaying.title}`,
        albumArt: nowPlaying.art,
      });
    };

    return () => {
      socket.close();
    };
  }, [store]);

  useEffect(() => {
    setAudio(new Audio(store.file));

    if (audio === null) {
      return;
    }

    audio.volume = store.volume / 100;

    function handleLoaded() {
      if (audio === null) {
        return;
      }

      if (audio.readyState >= 2) {
        if (audio.autoplay) {
          store.play();
        }

        store.setIsReady(true);
      } else {
        throw new Error("Failed to load sound file");
      }
    }

    function handlePlayPause(e: Event) {
      if (e.type === "play" && store.firstPlay) {
        if (store.firstPlay) {
          store.setFirstPlay(false);
        }
        store.play();
      }
      if (e.type === "pause") {
        store.pause();
      }
    }

    audio.addEventListener("loadeddata", handleLoaded);
    audio.addEventListener("pause", handlePlayPause);
    audio.addEventListener("play", handlePlayPause);

    if ("mediaSession" in navigator) {
      navigator.mediaSession.setActionHandler("play", () => {
        store.play();
      });
      navigator.mediaSession.setActionHandler("pause", () => {
        store.pause();
      });
    }
  }, []);

  useLayoutEffect(() => {
    if (audio === null) {
      return;
    }

    if (audio.src !== store.file) {
      audio.pause();

      audio.setAttribute("src", store.file);
      store.setFirstPlay(true);
    }

    if (!store.isPaused && store.firstPlay) {
      audio.load();
      audio.play().then(() => {
        store.setIsPlaying(true);
      });

      return;
    }

    audio.pause();
  }, [store.isPaused, store.file]);

  return (
    <>
      {children}
      <div
        className={classNames(
          "fixed bottom-4 right-2 left-2 z-50 mx-auto max-w-md transition-opacity duration-300 lg:hidden",
          {
            "opacity-0": !playerVisible,
            "opacity-100": playerVisible,
          },
        )}
      >
        <div
          className="flex h-20 w-full overflow-hidden rounded-2xl bg-white shadow-2xl"
          title={store.nowPlaying.full}
        >
          <div className="relative h-20 w-20">
            <Image
              src={store.nowPlaying.albumArt}
              alt={store.nowPlaying.full}
              fill
              className="object-cover"
            />
          </div>
          <div className="mx-4 flex flex-1 flex-col items-start justify-center overflow-hidden">
            <div className="max-w-full truncate font-display text-xl font-extrabold">
              {store.nowPlaying.title}
            </div>
            <div className="max-w-full truncate font-display text-sm">{store.nowPlaying.artist}</div>
          </div>
          <div className="flex items-center justify-center px-4">
            <button
              className="inline-flex items-center justify-center rounded-full bg-primary p-2 text-black"
              onClick={() => {
                if (store.isPlaying) {
                  store.pause();

                  if (store.file === radioStream) {
                    return;
                  }
                }

                if (store.file !== radioStream) {
                  store.resetFile();
                }

                store.play();
              }}
            >
              {(store.isPaused || !store.isPlaying) && <SvgPlay className="h-5 w-5 text-white" />}
              {store.isPlaying && <SvgPause className="h-5 w-5 text-white" />}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
