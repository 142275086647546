import * as React from "react";
import { SVGProps } from "react";
export default function SvgIconTunein(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width={96} height={43} viewBox="0 0 96 43" fill="none" {...props}>
      <path
        d="M59.52.48c-1.44 0-1.44 1.44-1.44 1.44v8.16H1.44C0 10.08 0 11.52 0 11.52v29.52c0 1.44 1.44 1.44 1.44 1.44h58.08c1.44 0 1.44-1.44 1.44-1.44v-8.16h32.88c1.44 0 1.44-1.44 1.44-1.44V1.92c0-1.44-1.44-1.44-1.44-1.44H59.52zm8.88 9.6h6.72v2.64h-2.16v8.16h2.4v2.64h-7.2v-2.64h2.16v-8.16H68.4v-2.64zm8.88 0h3.12l2.88 8.16v-8.16h2.64v13.44h-2.88l-3.12-7.92v7.92h-2.64V10.08zm-74.4 2.88h55.2V39.6H2.88V12.96zm7.2 6.72v2.64h2.88v10.56h2.64V22.32h2.88v-2.64h-8.4zm10.32 0v9.36s0 4.08 4.56 4.08 4.56-4.08 4.56-4.08v-9.36h-2.64v9.36s0 1.44-1.92 1.44-1.92-1.44-1.92-1.44v-9.36H20.4zm11.28 0v13.2h2.64v-7.44l2.64 7.44h3.36v-13.2h-2.88v7.44l-2.64-7.44h-3.12zm10.56 0v13.2h7.92v-2.64h-5.04V27.6h4.8v-2.64h-4.8v-2.64h4.8v-2.64h-7.68z"
        fill="currentColor"
      />
    </svg>
  );
}
