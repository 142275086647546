import * as React from "react";
import { SVGProps } from "react";
export default function SvgPlay(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M9.537 6.118A1 1 0 008 6.962v10.356a1 1 0 001.537.844l8.137-5.178a1 1 0 000-1.688L9.537 6.118z"
        fill="currentColor"
      />
    </svg>
  );
}
