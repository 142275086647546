// eslint-disable-next-line import/order
import PlayerProvider from "components/PlayerProvider";
import "css/tailwind.css";
import { AppProps } from "next/app";
import Head from "next/head";
import Script from "next/script";
import React from "react";

function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>
      <Script
        id="PreprPixel"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
                !function (e, t, n, p, o, a, i, s, c) {
  e[o] || ((i = e[o] = function () {
    i.process ? i.process.apply(i, arguments) : i.queue.push(arguments)
  }).queue = [], i.t = 1 * new Date, (s = t.createElement(n)).async = 1, s.src = "https://cdn.tracking.prepr.io/js/openpixel.min.js?t=" + 864e5 * Math.ceil(new Date / 864e5), (c = t.getElementsByTagName(n)[0]).parentNode.insertBefore(s, c))
}(window, document, "script", 0, "opix"),
  opix("init", "36ada6fa88b8321ac5162e1c90a9da5e86b69d61093cf85dc57c75f92344a7a1");
  `,
        }}
      />
      <PlayerProvider>
        <Component {...pageProps} />
      </PlayerProvider>
    </>
  );
}

export default App;
