import * as React from "react";
import { SVGProps } from "react";
export default function SvgLogo(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width={1074} height={416} viewBox="0 0 1074 416" fill="none" {...props}>
      <path d="M893.9 416l6.2-39.1h109.3L1029 275h45l-25.9 141H893.9z" fill="#FBFBFB" />
      <path
        d="M823.3 0c42.5 0 74.7 38.4 67.2 80.2l-16.7 94.3L935.3 93h96.5l-87.5 105.5 42 146.5h-90.5l-19-91c-13.7 15.4-22.9 34.2-26.5 54.5l-6.5 36.5h-81.5l61-345zM675.1 227.5c0 24 14.5 43.5 39 43.5 20.5 0 40-7.5 52-15l-15 86.5c-13 6-32.5 8-46 8-45.4 0-80.3-20.5-99-53.6l29.6-167.6C662 103.9 698.5 88 741.6 88c13.5 0 41.5 3.5 52.5 9l-15 85.5c-9.5-7.5-25.5-15-46.5-15-31.5 0-57.5 28.5-57.5 60zM546.7 105.8l-3.4 19.7c-12-16.9-33-31.9-65.5-31.9-33.2 0-61.1 14.5-81.6 37.4l-28.3 160.3c13 32.5 39 54 77.9 54 24.5 0 40.7-3.2 60.6-15.6l-2.1 15.1h81.5l44-251.2-83.1 12.2zm-73.4 164.9c-23.5 0-37.5-19-37.5-43 0-32 25.5-60.5 54-60.5 23.5 0 37.5 19 37.5 42.5 0 32-25.5 61-54 61zM402.8 25l-79.5 16.5-9 51.5H289c-3.6 21.1-10 41.6-18.8 60.9-1.3 2.9-2.7 5.8-4.2 8.6h31.2L279 264.8c-.7 4.1-1.1 8.1-1.1 12.1 0 36.9 29.9 68.1 68.3 68.1l56.6-320zM168 275.5h76.5L232 345H36.7l.6-3.4c7.2-40.1 24.2-77.8 49.6-109.7l55.3-69.4h-68L86.7 93h189.8c-7.2 42.3-25.4 81.9-52.7 114.9L168 275.5z"
        fill="#FBFBFB"
      />
      <path d="M171 25l-7.4 40.1H63.8L45 162.5H0L26.3 25H171z" fill="#FBFBFB" />
    </svg>
  );
}
