import * as React from "react";
import { SVGProps } from "react";
export default function SvgIconGooglePodcast(props: SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        d="M17.68 7.22v1.4c0 .68-.55 1.24-1.25 1.24-.69 0-1.25-.56-1.25-1.24v-1.4a1.25 1.25 0 011.25-1.25c.7 0 1.25.56 1.25 1.25m-4.43 1.14v7.27a1.25 1.25 0 01-2.5 0V8.36c0-.68.56-1.25 1.25-1.25s1.25.57 1.25 1.25M22 11.3v1.4c0 .68-.56 1.24-1.25 1.24s-1.25-.56-1.25-1.24v-1.4c0-.69.56-1.24 1.25-1.24S22 10.61 22 11.3m-17.5 0v1.4c0 .68-.56 1.24-1.25 1.24-.7 0-1.25-.56-1.25-1.24v-1.4c0-.69.55-1.24 1.25-1.24.69 0 1.25.55 1.25 1.24m4.32 4.08v1.39c0 .68-.56 1.23-1.25 1.23s-1.25-.55-1.25-1.23v-1.39c0-.7.56-1.25 1.25-1.25a1.25 1.25 0 011.25 1.25m4.43 3.98v1.39A1.25 1.25 0 0112 22a1.25 1.25 0 01-1.25-1.25v-1.39A1.25 1.25 0 0112 18.11a1.25 1.25 0 011.25 1.25m0-16.11v1.39A1.25 1.25 0 0112 5.89a1.25 1.25 0 01-1.25-1.25V3.25c0-.7.56-1.25 1.25-1.25a1.25 1.25 0 011.25 1.25m4.43 9.09v4.43c0 .69-.55 1.23-1.25 1.23-.69 0-1.25-.54-1.25-1.23v-4.43c0-.7.56-1.25 1.25-1.25.7 0 1.25.55 1.25 1.25M8.82 7.22v4.43c0 .7-.56 1.25-1.25 1.25a1.25 1.25 0 01-1.25-1.25V7.22a1.25 1.25 0 011.25-1.25 1.25 1.25 0 011.25 1.25z"
      />
    </svg>
  );
}
